import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { GetBulkDocumentInfo, GetBulkDocumentInfoAdhr, GetDocumentInfo, SetDocumentInfo } from '../../services/srvc-esin-realm';

import pdf2base64 from 'pdf-to-base64';
import { CaptureBasicSignature, CaptureCustomSignature } from '../../services/srvc-utilities';
import WebbLoaderIcon from '../webb/webb-loader-icon';
import UserEsinDone from '../esin/user-esin-done';
import DocumentFileModule from './adhr-docx-file';



const BulkAdhaarTestDocumentViewModule = () => {

const {enid,usid}=useParams()

const [loading,setLoading]=useState(true);
const [data,setData] = useState()
const [auth,setAuth]=useState(false)
const [file, setFile] = useState(false);
const [mmbr, setMember] = useState({});
const [signed,setSigned]=useState(false)
const [view,setView]=useState(true)
const [viewpasscode,setViewPasscode] = useState(false)

const [password,setPassword] = useState()

const [adno,setAdno] = useState('')
const [mobno,setMobno] = useState('')

const [complete,setComplete] = useState(false)

var [text,setText] = useState('')
var [otpstat,setOtpstat] = useState()

var [terms, setTerms] = useState(false)

var [next,setNext] = useState(false)
const [docs, setDocs] = useState()

const history = useHistory();

const authuser=JSON.parse(localStorage.getItem("auth"))


useEffect(()=>{

    setLoading(true)
    const fetchdata=async()=>{
        
         const docx = await GetBulkDocumentInfoAdhr({document:enid})
         var record = docx.data
         console.log(record)
         if(record==false)
         {
           setDocs(record)  
         }
         if(record!= false)
         {
           if(record.file.length==0){setDocs(false); return}
        
           window.location.href=record.list[0].sinz
          //  setData(record)
          //  setMember(record.list.find(item => item.enid === usid))
          //  var bs64 = await pdf2base64(record.file[0].link)
          //  setFile(bs64)
          //  setLoading(false);
         }
      
    }
    fetchdata()

},[enid,usid])

console.log(mmbr)


useEffect(() => {
    setLoading(true)
    if(data!=null && !mmbr) {
     
      const fetchInitialDocs=async() => {
        //  console.log("in the initial file load")

        // console.log(mmbr)
        
        var bs64 = await pdf2base64(data.file[0].link)
        // console.log(bs64)
        for(var i=0;i<mmbr.locn.length;i++)
        {
          if(mmbr.locn[i].type=="allx")
          {
             bs64= await CaptureBasicSignature({"file":bs64, "locn":mmbr.locn[i], "name":mmbr.name})
          }
          if(mmbr.locn[i].type=="lstx")
          {
             bs64= await CaptureBasicSignature({"file":bs64, "locn":mmbr.locn[i], "name":mmbr.name})
          }
          if(mmbr.locn[i].type=="fstx")
          {
             bs64= await CaptureBasicSignature({"file":bs64, "locn":mmbr.locn[i], "name":mmbr.name})
          }
          if(mmbr.locn[i].type=="cstm")
          {
            bs64=await CaptureCustomSignature({"file":bs64,"locn":mmbr.locn[i],"name":mmbr.name})

          }
          setFile(bs64)

        }

      setFile(bs64)
      setLoading(false)
    }
    fetchInitialDocs()
  }
},[data,mmbr])
//console.log(data)

useEffect(()=>{
    if(adno.length==0)
    {
        setText('')
    }
    if(adno.length==12)
    {
        setText('')
    }
    if(adno.length>1 && adno.length!=12){
        setText("Invald Aadhaar Number")
    }

},[adno])

const SendOTP =async()=>{
    setViewPasscode(true)
    setOtpstat(`OTP has been send on +91 ${mobno}`)
}

const sumitRequest= async()=>{

    if(password==="123456")
    {
        setLoading(true)
        const datx = {
            member:mmbr,
            trxnid: enid,
            feat:{qrcd:data.feat.qrcd},
            doc_id: enid,
            typedname:mmbr.name,
            method:"Signature Completed with Aadhaar + OTP",
            signpng:'',
            last: mmbr.rank === data.list.length ? true : false,
            base64: await pdf2base64(data.file[0].link),
            webhookurl:"https://ap-south-1.aws.data.mongodb-api.com/app/contrato-esin-rdujc/endpoint/hook/bulk/esin",
            // webhookurl:"https://webhook.site/74d0aaa2-c9c9-4f5d-970e-679fec9196e8",
            baseurl:"https://contrato.ws" 
          }
    
        //   console.log(datx)
          const res=await SetDocumentInfo(datx)
          console.log(res)
          setFile(res.data)
          setComplete(true)
          setLoading(false)
    }

    else{
        setOtpstat("Wrong OTP..")
    }

   

}

if(docs==false) return (<> <p  className='text-center text-danger mt-5 '>This Document is Not Ready</p><p  className='text-center text-primary' >Please Refresh page </p> <br/><br/><br/> <div style={{maxWidth:"120px", marginLeft:"auto", marginRight:"auto"}}> <button className='btn btn-outline-primary' onClick={()=> window.location.reload(true)}><i class="bi bi-arrow-clockwise"></i> Refresh</button> </div> </>)


if (loading)  return ( <> <WebbLoaderIcon /> </> )

  if (data.stat==11 && data.actv ==false)  return ( <> <UserEsinDone /> </> )

  if (mmbr.stat==6)  return ( <> <UserEsinDone /> </> )
  

  return (
    <div className=' '>

        <div className={`${next?"d-none":""}`} style={{maxWidth:"650px", marginLeft:"auto", marginRight:"auto"}}   >
        <p className='fw-bold text-center text-dark mt-3'>Review Contract Detail</p>
        <DocumentFileModule  signed={signed}  view={view} data={file}/>
        
        <button onClick={()=>setNext(true)} className='btn btn-info back-color-main text-color-wite w-100 mt-3'>Go For e-Sign</button>

        </div>
        {
            complete?
            <>
            <div className='w-100 mt-5'>
                <div style={{maxWidth:"250px",marginLeft:"auto", marginRight:"auto"}}>
                <img style={{width:"260px", height:"260px"}} src="https://cliply.co/wp-content/uploads/2021/03/372103860_CHECK_MARK_400px.gif"/>
                </div>

                <div className='mt-4'>
                    <div className='border p-3 bg-success' style={{maxWidth:"350px",marginLeft:"auto", marginRight:"auto"}}>
                        <p className='text-light text-center'>Thanks From Contrato</p>
                        <p className='text-light text-center'>We Have Successfully Captured Your Signature</p>
                    </div>

                </div>

            </div>
            </>
            :
            <>
                
                <div className={` w-100 ${next?"":"d-none"}`} style={{backgroundColor:"#F9E7CA", marginTop:"0px",height:"100vh"}}>
                <div className='border  bg-white' style={{maxWidth:"650px", marginLeft:"auto", marginRight:"auto"}}>
                <div className='head' style={{backgroundColor:"#3316EB"}}><p className='p-3 text-light text-center'>NSDL Electronic Signature Service (Testing)</p></div>
                <div><p className='text-danger text-center'>THIS IS A DEMO TESTING SERVICE FOR NSDL e-SIGN</p></div>
                
                <div className='px-3'>
                    <div className='d-flex' style={{height:"20px"}}>
                        <div className='border text-center w-50' style={{backgroundColor:"#B3D4F9"}}>
                        <p className='small text-center '>ASP Name</p>
                        </div>
                        <div className='border text-center w-50' style={{backgroundColor:"#E5F0FB"}}> 
                        <p className='small text-center'>NOWY AZ PRIVATE LIMITED</p> 
                        </div>
                    </div>
                    <div className='d-flex' style={{height:"20px"}}>
                        <div className='border text-center w-50' style={{backgroundColor:"#B3D4F9"}}>
                        <p className='small text-center '>Transaction ID</p>
                        </div>
                        <div className='border text-center w-50' style={{backgroundColor:"#E5F0FB"}}> 
                        <p className='small text-center'>{Date.now().toString()}</p> 
                        </div>
                    </div>
                    <div className='d-flex' style={{height:"20px"}}>
                        <div className='border text-center w-50' style={{backgroundColor:"#B3D4F9"}}>
                        <p className='small text-center '>Date & Time</p>
                        </div>
                        <div className='border text-center w-50' style={{backgroundColor:"#E5F0FB"}}> 
                        <p className='small text-center'>{new Date().toTimeString()}</p> 
                        </div>
                    </div>
                </div>

                <br/>

                <div className='content px-3'>
                    <div className='d-flex'>
                        <input value={terms} onClick={()=>setTerms(!terms)}  type="checkbox" /><p className='small mx-2'>I hereby authorize NSDL e-Governance Infrastructure Limited (NSDL e-Gov) to-</p>
                    </div> 

                    <p className='small'>1. Use my aadhaar / virtual id details (as applicable) for the purpose of electronic signing of uploaded or generated account opening/onboarding/loan/lending/financial documents or contracts for/with NOWY AZ PRIVATE LIMITED and authenticate my identity through the aadhaar authentication system (Aadhaar based e-KYC service of UIDAI) in accordance with the provision of the aadhaar (Targeted Delivery of Financial and other subsidies,benefits and services) Act,2016 and the allied rules and regulations notified there under and for no other purpose</p>
                    <p className='small'>2. Authenticate my Aadhaar / Virtual ID throught OTP or Biometrics for authenticating my identity through the aadhaar authentication system for obtaining my e-KYC through Aadhaar based e-KYC service of UIDAI and use my photo and demographic details (Name, Gender, Date of Birth and Adress) For the purpose of electronics signing of uploaded or generated account opening/onboarding/loan/lending/financial documents or cntracts for/with NOWY AZ PRIVATE LIMITED</p>
                    <p className='small'>3. I understand that security and confidentiality of personal identity data provided, for the purpose of Aadhaar based authentication is ensured by NSDL e-Gov and the data will be stored by NSDL e-Gov till such time as mentioned in guidelines from UIDAI from time to time.</p>
                </div>

                <div className='px-3 mt-3 mb-5'>
                    <input value={adno} onChange={(e)=>setAdno(e.target.value)} className={`form-control w-50 ${viewpasscode==false?"":'d-none'}`} placeholder='Aadhaar Number'/>
                    <p className='small text-danger'>{text}</p>
                    <div className={`d-flex ${viewpasscode==false?"":'d-none'}`}>
                    <input value={mobno} onChange={(e)=>setMobno(e.target.value)} className='form-control w-50' placeholder='Registered Mobile Number'/>
                    <button disabled={!terms} onClick={()=>SendOTP()} className={`btn btn-sm btn-outline-success rounded-none mx-5 ${viewpasscode==false?"":'d-none'} `}>Send OTP</button>
                    </div>


                    <div className={`d-flex ${viewpasscode==true?"":'d-none'}`}>
                    <input value={password} onChange={(e)=>{setPassword(e.target.value);setOtpstat('')}} className='form-control w-50' placeholder='6 Digit OTP'/>
                    <button onClick={()=>sumitRequest()} className='btn btn-sm btn-outline-success rounded-none mx-5'>Submit OTP</button>
                    </div>
                    <p className={`small text-danger mt-2 ${viewpasscode==true?"":'d-none'}`}>{otpstat}</p>

                </div>
                
                </div>
                </div>
            </>
        }

        

    </div>
  )
}

export default BulkAdhaarTestDocumentViewModule
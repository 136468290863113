// esin status
import icon from '../../media/icon.png';
import WebbSiteName from '../webb/webb-site-name';

export default function UserEsinWait() {

  return (
    <>
      <div className="" style={{height:'24vh'}}></div>
      <div className="text-center py-1">
        <img src={icon}
          className="mb-3"
          width="33" height="33" alt="..." 
        />
        <p className="">Document Pending on Other or Not Ready</p>
      </div>

      <div className="fixed-bottom text-center" style={{bottom: '1.5rem'}}>
        <WebbSiteName/>
      </div>

    </>
    )
  }
import React, { useState } from 'react'
import '../../media/nameStyle.css'
import { ResizeSignatureImage } from '../../services/srvc-esin-realm'

const EsinTypeSignature = (props) => {


const [name,setName] = useState(props.name)
const [pngImage,setPngImage] = useState()

const [classname, setClassname] = useState({
    "name_one":false,
    "name_two":false,
    "name_three":false,
    "name_four":false,
    "name_five":false,
    "name_six":false,
    "name_seven":false,
    "name_eight":false,
    "name_nine":false

})

const resetClass = async()=>{
    
}

const handleChange = async(key, val, font) => {

   

    var canvas = document.getElementById('myCanvas');
    // canvas.width=600
    // canvas.height=80
    var ctx = canvas.getContext('2d');

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.font = `40px ${font} `;
    ctx.textAlign = 'left';
    // ctx. textBaseline = 'left';
    ctx.fillStyle = 'blue';  // a color name or by using rgb/rgba/hex values
    ctx.fillText(name, 20, 50);

    var pngBase64 = canvas.toDataURL().split(";base64,").pop()
   setPngImage(pngBase64)
    
    var obj = {
        "name_one":false,
        "name_two":false,
        "name_three":false,
        "name_four":false,
        "name_five":false,
        "name_six":false,
        "name_seven":false,
        "name_eight":false,
        "name_nine":false
    
    }

    var newobj = { ...obj, [key]: val }
    console.log(newobj)
    setClassname(newobj);
    Object.entries(newobj).map(entry => {
        let key = entry[0];
        let value = entry[1];
        if(value==true)
        {
            document.getElementsByClassName(key)[0].style.background="aqua"
        }
        if(value==false){
            document.getElementsByClassName(key)[0].style.background="none"
        }
    });

   
  }





const ClearName= async()=>{
    setName('')
    var obj = {
        "name_one":false,
        "name_two":false,
        "name_three":false,
        "name_four":false,
        "name_five":false,
        "name_six":false,
        "name_seven":false,
        "name_eight":false,
        "name_nine":false
    
    }
    Object.entries(obj).map(entry => {
        let key = entry[0];
        let value = entry[1];
        if(value==true)
        {
            document.getElementsByClassName(key)[0].style.background="aqua"
        }
        if(value==false){
            document.getElementsByClassName(key)[0].style.background="none"
        }
    });
    var canvas = document.getElementById('myCanvas');
    // canvas.width=800
    // canvas.height=50
    var ctx = canvas.getContext('2d');

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    
}


const submitName = async()=>{
    //var result = await ResizeSignatureImage(JSON.stringify({"imagebase64":pngImage, "size":{"width":"350","height":"50"}}))
    // setFile(result.resizedImage)
    props.signature({type:"draw", base64:pngImage})
}



  return (
    <div>
    <div className='' style={{maxWidth: "600px", height: "350px", marginLeft:"auto", marginRight:"auto"}}>
        <div className=''>
            <input value={name} onChange={(e)=>setName(e.target.value)} className='form-control'/>
        </div>
        <hr className='w-100 text-dark'/>

        <div className='row text-center' style={{justifyContent: "space-between"}}>
            <div onClick={()=>handleChange("name_one",true,"Sofia")} style={{cursor:"pointer"}} className='col-4 col-md-4  col-lg-4 name_one border p-3  '>{name}</div>
            <div onClick={()=>handleChange("name_two",true,"Trirong")} style={{cursor:"pointer"}} className='col-4 col-md-4 col-lg-4 name_two border p-3'>{name}</div>
            <div onClick={()=>handleChange("name_three",true,"Audiowide")} style={{cursor:"pointer"}} className='col-4 col-md-4 col-lg-4 name_three border p-3' >{name}</div>
        </div>
        <div className='row text-center mt-4'>
            <div onClick={()=>handleChange("name_four",true, "Roboto")} style={{cursor:"pointer"}} className='col-4 col-md-4 col-lg-4 name_four border p-3'>{name}</div>
            <div onClick={()=>handleChange("name_five",true, "Lobster")} style={{cursor:"pointer"}} className='col-4 col-md-4 col-lg-4 name_five border p-3'>{name}</div>
            <div onClick={()=>handleChange("name_six",true,"Chelsea Market")} style={{cursor:"pointer"}} className='col-4 col-md-4 col-lg-4 name_six border p-3'>{name}</div>
        </div>
        <div className='row text-center mt-4'>
            <div onClick={()=>handleChange("name_seven",true,"Great Vibes")} style={{cursor:"pointer"}} className='col-4 col-md-4 col-lg-4 name_seven border p-3'>{name}</div>
            <div onClick={()=>handleChange("name_eight",true,"Fuzzy Bubbles, cursive")} style={{cursor:"pointer"}} className='col-4 col-md-4 col-lg-4 name_eight border p-3'>{name}</div>
            <div onClick={()=>handleChange("name_nine",true, "Inspiration")} style={{cursor:"pointer"}} className='col-4 col-md-4 col-lg-4 name_nine border p-3'>{name}</div>
        </div>
        
        <div className='row text-center mt-4'>
            <div className='col-4 col-md-4 col-lg-4 '><canvas width="600px" height="70px" style={{border:"1px solid red"}}   className='d-none' id='myCanvas' ></canvas></div>
            <div className='col-4 col-md-4 col-lg-4'></div>
            <div className='col-4 col-md-4 col-lg-4'></div>

        </div>

      
    </div>

    

    

    <div className='container mt-5' style={{maxWidth:"600px", marginLeft:"auto", marginRight:"auto"}}>
        <div className='row'>
            <div className='col-6 col-md-6 col-lg-6'>
                <button onClick={()=>submitName()} className='btn btn-info rounded-none w-100' >Save</button>    
            </div>
            <div className='col-6 col-md-6 col-lg-6'>
                <p style={{cursor:"pointer"}} onClick={()=>ClearName()} className='text-center text-danger mt-2'>Clear</p>
            </div>

        </div>
        

    </div>

   
    </div>
  )
}

export default EsinTypeSignature
// esignature page

import React, { useEffect, useState,useRef } from 'react'
import { useParams } from 'react-router'
import SignaturePad from 'react-signature-canvas'
import { CallAzure, CallLambda } from '../realm/callAzure'
import { cmyk, degrees, grayscale, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { VerifyOTP } from '../realm/checkotp'
import { CreateOTP } from '../realm/createotp'
import { getDocxfile } from '../realm/getFileData'
import {encode,decode} from 'base64-arraybuffer'
export default function Signature () {

    var enid = useParams().enid
    var usid = useParams().usid

    const [data,setData]=useState()
    const [initfile,setInitFile]=useState()
    const [name,setName]=useState()
    const [email, setEmail]=useState()
    const [showsign,setShowSign]=useState(false)
    const [pad,setPad]=useState(false)
    const [file,setFile]=useState()
    const [view,setView]=useState(false)
    const [otpview,setOtpview]=useState(false)
    const [signer,setSigner]=useState()
    const [text,setText]=useState()
    const [sigstr,setSigstr]=useState('')
    const [otptext,setOtpText]=useState({"color":"green","text":"OTP has been Sent to Signer's Email"})
    const [otp,setOtp]=useState()
    let sigPad=useRef({})
    var base64
    // let sigstr=''
    console.log("enid-",enid)
    console.log("usid:- ", usid)
    useEffect(()=>{
        
        const fetchdata=async()=>{
            var res=await getDocxfile(enid)
            //  console.log(res)

            setData(res)
            if(res!=null)
            {
            res.list.map(item=>{
                if(item.enid==usid)
                {
                    // console.log(item)
                    setSigner(item)
                }
            })  
             setFile(res.file[0].file)
            res.list.map((item,i)=>{
                if(item.enid==usid && item.rank==1 && item.stat==0 )
                {
                    console.log("in effect")
                    setView(true)
                }
                if(item.enid==usid && item.rank!=1 && item.stat==0)
                {
                    if(res.list[i-1].stat==1)
                    {
                        setView(true)
                    }
                }if(item.enid==usid && item.stat==1)
                {
                    setText({color:"success",text:"Congratulations..!!! You have already Signed the doc"})
                }
                if(item.enid==usid && item.rank!=1 && item.stat==0)
                {
                    if(res.list[i-1].stat==0)
                    {
                    setText({color:"danger",text:"Sorry... This file is not ready for you to sign"})
                    }
                }
               
            })
        }
        }
        fetchdata()

    },[enid])

    
    useEffect(()=>{
        if(data!=null && signer!=null)
        {
            const fetchInitialDocs=async()=>{
                console.log("in the initial file setup")
                const existingPdfBytes= await decode(data.file[0].file)
                const pdfDoc = await PDFDocument.load(existingPdfBytes)
                const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
          
            const pages = pdfDoc.getPages()
            var len
            if(data.locn=="allx")
                {
                    len=pages.length
                }
            if(data.locn=="fstx" || data.locn=="lstx" || data.locn=="endh" || data.locn=="endv" || data.locn=="dynm")
                {
                    len=1
                }
          

 
                 for(var i=0;i<len;i++)
                 {
                   
                   var x1= parseInt(signer.locn.x1)
                   
                   var y1=parseInt(signer.locn.y1)
                  
                   var firstNo
                   if(data.locn=="lstx" || data.locn=="dynm")
                   {
                     firstNo = pages[pages.length-1]
                   }
                   if(data.locn!="lstx"){
                     firstNo = pages[i]
                   }
                   if(data.locn=="endv")
                   {
                       firstNo=pages[pages.length-1]
                   }
                   if(data.locn=="endh")
                   {
                     firstNo=pages[pages.length-1]  
                   }
                   
                   if(data.locn=="endv")
                   {

                    firstNo.drawText(`${signer.name}`, {
                        x: x1-430,
                        y:y1+10,
                      //y:100,
                        size: 15,
                        font: helveticaFont,
                        color: rgb(0, 0.2, 0.5),
                        rotate: degrees(0),
                      })

                      
                    firstNo.drawRectangle({
                        x:x1-100,
                        y:y1-20,
                        width:150,
                        height:45,
                        color: cmyk(0.02,0.01,0.00,0.02),
                       
                    })
                    firstNo.drawText(`${signer.name}`, {
                        x: x1-70,
                        y:y1+7,
                      //y:100,
                        size: 10,
                        font: helveticaFont,
                        color: rgb(0, 0.2, 0.5),
                        rotate: degrees(0),
                      })
                     firstNo.drawText(`Your Sign will be placed here`, {
                        x: x1-70,
                        y:y1-4,
                      //y:100,
                        size: 7,
                        font: helveticaFont,
                        color: rgb(0, 0.2, 0.5),
                        rotate: degrees(0),
                      })

                   }
                   if(data.locn=="endh")
                   {
  
                    firstNo.drawRectangle({
                        x:x1,
                        y:y1-110,
                        width:150,
                        height:45,
                        color: cmyk(0.02,0.01,0.00,0.02),
                       
                    })
                    firstNo.drawText(`${signer.name}`, {
                        x: x1+15,
                        y:y1-91,
                      //y:100,
                        size: 10,
                        font: helveticaFont,
                        color: rgb(0, 0.2, 0.5),
                        rotate: degrees(0),
                      })
                     firstNo.drawText(`Your Sign will be placed here`, {
                        x: x1+15,
                        y:y1-98,
                      //y:100,
                        size: 7,
                        font: helveticaFont,
                        color: rgb(0, 0.2, 0.5),
                        rotate: degrees(0),
                      })

                   }
                   
                   else{
                    firstNo.drawRectangle({
                        x:x1,
                        y:y1,
                        width:150,
                        height:45,
                        color: cmyk(0.02,0.01,0.00,0.02),
                       
                    })
                    firstNo.drawText(`${signer.name}`, {
                        x: x1+30,
                        y:y1+27,
                      //y:100,
                        size: 10,
                        font: helveticaFont,
                        color: rgb(0, 0.2, 0.5),
                        rotate: degrees(0),
                      })
                     firstNo.drawText(`Your Sign will be placed here`, {
                        x: x1+30,
                        y:y1+16,
                      //y:100,
                        size: 7,
                        font: helveticaFont,
                        color: rgb(0, 0.2, 0.5),
                        rotate: degrees(0),
                      })
                   }
                   
                   
                     
                     
                 
       
                }
            const pdfBytes = await pdfDoc.save()
            const base64=await encode(pdfBytes)
           // console.log(base64)
            setFile(base64)
            }
            fetchInitialDocs()
        }
        

    },[signer])


    const createOTP=async()=>{
       
        if(!signer.emid || !signer.name)
        {
            window.alert('kindly fill name and email to proceed')
            return
        }
        else
        {
            setOtpview(true)
            console.log(signer.emid)
            var res=await CreateOTP({"email":signer.emid})
            console.log(res)

        }
        
    }

    const verifyOTP=async()=>{
        if(!otp)
        {
            window.alert("fill the OTP")
            return
        }
        else{
            var obj={"email":signer.emid,"otp":otp.toString()}
            var res= await VerifyOTP(obj)
            //console.log(res)
            if(res=="true")
            {
                setOtpText({"color":"green","text":"Congratulations..OTP Verified....."})
                var rank
                data.list.map(item=>{
                    if(item.enid==usid && item.stat==0)
                    {
                        rank=item.rank
                    }
                })
                if(sigstr!='')
                {
                var temp=sigstr.split("base64")
                    var temp1=temp[1].split(",")
                     base64=temp1[1]
                }    
                
                var payload={
                    email:signer.emid,
                    trxnid:enid,
                    doc_id:enid,
                    name:signer.name,
                    usid:usid,
                    base64:data.file[0].file,
                    rank:rank,
                    posn:{
                        x1:parseInt(signer.locn.x1),
                        y1:parseInt(signer.locn.y1),
                        x2:parseInt(signer.locn.x2),
                        y2:parseInt(signer.locn.y2),
                    },
                    signpng:sigstr!=''?base64:'',
                    type:data.locn,
                    locn:data.locn,
                    mmbr:data.list.length,
                    last:rank==data.list.length?true:false,
                    baseurl:"https://settlefirst.io"
                    
                }
                console.log(payload)

                 if(data.locn=="allx" || data.locn=="lstx" || data.locn=="fstx")
                 {
                     // calling the api- /docs/esign/success
                     await fetch("/docs/esign/success",{
                        method:"post",
                        headers:{
                            "Content-Type":"application/json"
                        },
                        body:JSON.stringify(payload)
                    })
                    .then(res=>res.json())
                    .then(resu=>{
                        setFile(resu.data)
                    })
                 }
                 if(data.locn=="endv" || data.locn=="endh" || data.locn=="dynm")
                 {
                     // calling the api  /sign/table/success
                     await fetch("/sign/table/success",{
                        method:"post",
                        headers:{
                            "Content-Type":"application/json"
                        },
                        body:JSON.stringify(payload)
                    })
                    .then(res=>res.json())
                    .then(resu=>{
                        setFile(resu.data)
                    })

                 }

                
            }
            if(res=="false")
            {
               setOtpText({"color":"red","text":"Invalid OTP..Try again"})
            }
            
        }


    }

    const saveSign=async()=>{
    setSigstr(sigPad.current.toDataURL())  
    console.log(sigstr)
    setPad(false)
  setShowSign(true)
   // previewSign()
    
    }

    const clearSign=async()=>{
        sigPad.current.clear()
    }

   


    return (
        <div>
            {
                data!=null && data.sort=="0123" ?
                <>
                 <div className="d-flex">
            <div className="w-100">
                {
                    file?
                    <>
                    <embed style={{width:"60%",height:"100vh"}} src={`data:application/pdf;base64,${file}`} type="application/pdf" />
                    </>:
                    <>
                    <p>Loading file......</p>
                    </>
                }
                
               
           
             </div>
              
             <div style={{marginLeft:"-500px"}}>
               
            
           {
                otpview?
               <>
               <input  style={{width:"450px"}} value={otp} className="form-control mt-5" onChange={(e)=>setOtp(e.target.value)} type="text" placeholder="OTP"  />

               <p className="mt-3" style={{color:otptext.color}}>{otptext.text}</p>

               <button onClick={()=>verifyOTP()} className="btn btn-primary w-100 mt-5">Verify OTP</button>
              
               </>
               :
               <>
               {
                   signer!=null?
                   <>
                   <p style={{cursor:"pointer"}} onClick={()=>setPad(true)} className="mt-3">Sign with Signature-pad?</p> 
                   {
                       pad?
                       <>
                       <span onClick={()=>{setPad(false);setSigstr('')}} className="" style={{marginLeft:"450px",cursor:"pointer"}}>X</span><br/>
                        <div  className="border" style={{height:"250px", backgroundColor:"white"}}>
                            <SignaturePad
                            canvasProps={{width:450, height:250}}
                            ref={sigPad}
                            penColor="blue"

                            />
                           
                            
                        </div>
                        <button onClick={()=>clearSign()} className="mt-3 ">clear</button>
                            <button onClick={()=>saveSign()} className="mx-3 mt-3">save</button><br/>
                       </>
                       :
                       <>
                       </>
                   } 
                   {
                       showsign?
                       <div style={{backgroundColor:"white"}}>
                       {console.log(sigstr)}
                       <img className="border" src={sigstr} style={{width:"450px", height:"100px"}} /><br/>
                       </div>
                       :
                       <>
                       </>
                   }
                  
                    <label className="mt-3 text-primary">Signer Name</label>     
           <input disabled value={signer.name} type="text" style={{width:"450px"}} className="form-control" placeholder="name" />
           <br/>
           <label className="mt-1 text-primary">Signer Email</label>
           <input disabled value={signer.emid}  type="text" className="form-control  " placeholder="email" />
           
           <button onClick={()=>createOTP()} className="btn btn-primary w-100 mt-5">Send OTP</button><br/>
           
                   </>
                   :
                   <>
                   </>
               }
           
               </>
           }
           <hr/>
           </div>
          
           </div>
                </>
                :
                <>
                 {
                view?
                <>
                 {
            data!=null?
            <div className="d-flex">
            <div className="w-100">
           
                <embed style={{width:"60%",height:"100vh"}} src={`data:application/pdf;base64,${file}`} type="application/pdf" />
               
           
             </div>
             <div className="" style={{marginLeft:"-500px"}}>
                 
            
           {
               otpview?
               <>
               <input  style={{width:"450px"}} value={otp} className="form-control mt-5" onChange={(e)=>setOtp(e.target.value)} type="text" placeholder="OTP"  />

               <p className="mt-3" style={{color:otptext.color}}>{otptext.text}</p>

               <button onClick={()=>verifyOTP()} className="btn btn-primary w-100 mt-5">Verify OTP</button>
               </>
               :
               <>
            <label className="mt-5 text-primary">Signer Name</label>     
           <input disabled value={signer.name} type="text" style={{width:"450px"}} className="form-control" placeholder="name" />
           <br/>
           <label className="mt-1 text-primary">Signer Email</label>
           <input disabled value={signer.emid}  type="text" className="form-control  " placeholder="email" />
           <button onClick={()=>createOTP()} className="btn btn-primary w-100 mt-5">Send OTP</button>
               </>
           }
           <hr/>
           </div>
          
           </div>
            :
            <>
            </>
            }
                </>
                :
                <>
                {
                text!=null?
                <>
                <h5 className="text-center mt-5" style={{color:`${text.color=="success"?"green":"red"}`}}>{text.text}</h5>
                </>
                :
                <>
                </>
                }
                
                </>
            }
                </>
            }
            <div style={{marginLeft:"800px"}}>
           
            </div>
            {
                pad?
                <>
                </>
                :
                <>
                </>
            }
           
           
          
               
            
        </div>
    )
}
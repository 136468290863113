import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { DeligateUser, GetDocumentInfo, GetDocumentInfoMail, saveUserAction } from '../../services/srvc-esin-realm'
import UserEsinDone from '../esin/user-esin-done'
import WebbLoaderIcon from '../webb/webb-loader-icon'

const NextMailCustomModule = () => {

    const {enid,usid}=useParams()

    const authuser=JSON.parse(localStorage.getItem("auth"))

    const [loading,setLoading]=useState(true)
    const [data,setData]=useState([])
    const [mmbr,setMember]=useState({})
    const [next,setNext]=useState(true)
    const [name,setName]=useState('')
    const [email,setEmail]=useState('')
    const [mob,setMob]=useState('')
    const [success,setSuccess]=useState()

    const [view,setView]=useState(false)

    const history=useHistory()

    useEffect(()=>{
        setLoading(true)
        const fetchdata=async()=>{
          const docx = (await GetDocumentInfoMail({document:enid})).data
            // console.log(docx)
            setData(docx)
            setMember (docx.list.find(item => item.enid === usid))
            if(authuser==null)
            {
                if(docx.feat.auth==false)
                {
                    if(docx.feat.next==false){
                        // history.push(`/kyc/upload/docs/${enid}/${usid}`)
                        history.push(`/esma/docs/${enid}/${usid}`) 

                    }
                    if(docx.feat.next==true)
                    {
                        setNext(true)
                    }
                
                }
                if(docx.feat.auth==true)
                {
                    history.push(`/auth/docs/${enid}/${usid}`)
                }
                
                
            }
            
            if(authuser!=null)
            {
                if(docx.list.find(item => item.enid === usid).enid==authuser.usid && authuser.verf=="true")
                {
                    // console.log("verified")
                    if(docx.feat.next==false)
                    {
                        // history.push(`/kyc/upload/docs/${enid}/${usid}`)
                        history.push(`/esma/docs/${enid}/${usid}`) 

                    }
                    if(docx.feat.next==true)
                    {
                        setNext(true)
                    }
                }
                else{
                    // console.log("not verified")
                     history.push(`/auth/docs/${enid}/${usid}`)
                }
            }
           
          setLoading(false)
          
        }
        fetchdata()
    
      },[enid])
    //   console.log(mmbr)

    const deligate=async()=>{
        if(!name || !email || !mob)
        {
            window.alert("kindly fill all the fields")
        }
        else{
            setLoading(true)
            const res=await DeligateUser({"name":name,"email":email,"mob":mob,"usid":mmbr.usid,"dcid":data.clnt.trid,"enid":data.enid, "username":mmbr.name,"webb":data.clnt.webb})
            // console.log(res)
            setLoading(false)
            setNext(false)
            setSuccess(true)
            var obj ={data:{"memo":`${mmbr.name} Deligated To ${name}`,"document":data.clnt.trid },"user":mmbr.usid}
            await saveUserAction(JSON.stringify(obj))
            setTimeout(function () {
            localStorage.clear("auth")
            history.push("/")
            }, 2000);
            
        }
    }


if (mmbr!=null && mmbr.stat===1)  return ( <> <UserEsinDone /> </> )
    if(loading) return(<><WebbLoaderIcon/></>)
    return (
        <>
       {
           next?
           <>
            <div>
               
           
            {
                view?
                <div className='border p-3 mt-3 bg-white' style={{maxWidth:"550px", marginLeft:"auto", marginRight:"auto"}}>
                    <h6 className='text-primary text-center fw-bold'>Information Regarding Deligate User</h6>
                    <hr/>
                <input className='form-control mt-2' type="text" value={name} onChange={(e)=>setName(e.target.value)} placeholder='Deligate Name' />
                <input className='form-control mt-2' type="text" value={email} onChange={(e)=>setEmail(e.target.value)} placeholder='Deligate Email' />
                <input className='form-control mt-2' type="text" value={mob} onChange={(e)=>setMob(e.target.value)} placeholder='Deligate Mobile' />
                <div className='d-flex'>
                    <div className='me-auto'>
                    <button onClick={()=>setView(false)} className='btn btn-primary mt-5 mb-5'>Go Back</button>
                    </div>
                    <div className='text-end'>
                    <button onClick={()=>deligate()} className='btn btn-primary mt-5 mb-5'>Deligate</button>
                    </div>
                </div>
                
                </div>:
                <>
                 <>
                <div onClick={()=>setView(true)} style={{maxWidth:"500px", marginLeft:"auto", marginRight:"auto"}} className="mt-5" >
                <div className="p-3 d-flex bg-wite hilite" >
                <div className="me-auto">
                    <p className="fw-bold m-0">Deligate To Other</p>
                    
                </div>
                <div className="text-end py-2">
                    <i className="bi-chevron-right text-color-tone"></i>
                </div>
                </div>
                </div>
               
            </>
            <>
                <div onClick={()=>history.push(`/kyc/upload/docs/${enid}/${usid}`)} style={{maxWidth:"500px", marginLeft:"auto", marginRight:"auto"}} className="mt-2" >
                <div className="p-3 d-flex bg-wite hilite" >
                <div className="me-auto">
                    <p className="fw-bold m-0">Go For Signature</p>
                    
                </div>
                <div className="text-end py-2">
                    <i className="bi-chevron-right text-color-tone"></i>
                </div>
                </div>
                </div>
                
            </>
                </>
            }

           
            
        </div>
           </>
           :
           <>
           
           </>
       }

       {
           success?
           <>
           <div className='border mt-5' style={{maxWidth:"650px", height:"150px", marginLeft:"auto", marginRight:"auto", backgroundColor:"#BAF2B5"}} >
                <p className='text-dark text-center fw-bold mt-4'>Congratulations... You have SuccessFully Deligated Signature Process to {name}</p>
                <p className='text-dark text-center fw-bold'>We Will Send The Invitation Mail To {name}</p>
                <h6 className='text-dark text-center fw-bold'>Now You Can Close The Tab</h6>

           </div>
           </>:
           <>
           </>
       }
        
        </>
        
    )
}

export default NextMailCustomModule

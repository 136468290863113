import * as Realm from 'realm-web';
const app = new Realm.App({ id:"client-app-esin-uwioh"});

export const getDocxfile = async (id) => {
    const credentials = Realm.Credentials.anonymous();
    const user = await app.logIn(credentials);
    // console.log(item)
    // const result = await app.currentUser.functions.uploadBanksData(item)
    const result = await app.currentUser.functions.getDocxData(id)
     console.log(result)

    return (result);
  }
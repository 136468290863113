// esignature page
import React, { useEffect, useState } from 'react';

import WebbLoaderIcon from '../webb/webb-loader-icon';

export default function DocumentFileModule (props) {
  const data = props.data;
  const view= props.view
  const signed=props.signed
  const status = props.status

  //console.log(status)

  const [docurl, setDocUrl] = useState();
  const [loading, setLoading] = useState(true);
 
  useEffect(()=>{
    if(status== true)
    {
      var binary_string = window.atob(data);
      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
          bytes[i] = binary_string.charCodeAt(i);
      }
      // console.log(bytes)
      const docUrl = URL.createObjectURL(new Blob([bytes], {type: 'application/pdf'}))
      setDocUrl(docUrl)
      setLoading(false)
    }
    else{
      setLoading(true)
    }
  },[data,status])

  
  if (loading)  return (
  <>
  <div className="bg-secondary" style={{height:'64vh'}}>
    <div className="text-center py-1">
        <div class="spinner-border text-primary mt-5" role="status">
        </div>
      <p className="text-light ">Loading pdf....</p>
    </div>
    </div>
  </>
  )

  const OpenSignedPDF=()=>{
    
    const linkSource = `data:application/pdf;base64,${data}`;
    const downloadLink = document.createElement("a");
    const fileName = Date.now()+".pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click()
  }
  
  return (
  <>
   
    <div>
      
    
    <div className={`${view == true && signed == true ?"mt-1":"d-none"}`}>
          <div className='mb-2 d-flex' style={{maxWidth:"760px", height:"100px", backgroundColor:"#FFFFFF" , marginLeft:"auto", marginRight:"auto"}}>
            <div>
             <img style={{width:"170px", height:"100px"}} src='https://i.gifer.com/origin/11/1184b4c0aa977f925dde58d2075772dd_w200.gif' />
            </div>
            
            <div>
              <h4 className='text-center text-success fw-bold my-auto mt-1 p-1 mx-5'>Congratulations</h4>
              <h6 className='text-center text-success mt-1 mx-5' >Your signature has been captured</h6> 
            </div>
          </div>
    </div>

    {/* <div className={`${!signed ?"":"d-none"}`}>
      <div className='border border-dark mt-4'>
        <embed 
        style={{width:"100%",height:"100vh"}} 
        // src={`data:application/pdf;base64,${data}`+ "#toolbar=0"} 
        src={link+"#toolbar=0"}
        type="application/pdf" 
        />
      </div>
      
    </div>  */}
      <div className={`${view ?"":"d-none"}`}>       
      <iframe width="100%" height="700" src={docurl+'#toolbar=0'} />
      </div>

      <div className={`${signed==true?'':"d-none"}`}>
      <div className='text-center mt-3 mx-5 mb-2'><button className='btn btn-primary w-50'> <small onClick={()=>OpenSignedPDF()}>Download signed file</small></button></div>
      </div>

    </div>


  </>
  )
}
import * as Realm from 'realm-web';
const app = new Realm.App({ id: process.env.REACT_APP_MNDB_RELM_MAIN });

// Function Calls

export const AuthWebbUser = async () => {
  //login with webb
  const credentials = Realm.Credentials.apiKey(process.env.REACT_APP_MNDB_AUTH_APIK);
  const user = await app.logIn(credentials);
  //console.log('email user - ',app.currentUser.id)
  // return ({"auth":id, "usrx":user.id});
  // console.log(user)
  return user
}

export const AuthUserSessionX = async() => {
  // logout current user
  //console.log('user', app.currentUser)
  await app.currentUser.logOut()
}
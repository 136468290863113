import React from 'react'
import { useRef, useState } from 'react';
import SignaturePad from 'react-signature-canvas'

const EsinDrawSignature = (props) => {
    let signRef=useRef({})

    const clearSign=async()=>{
        signRef.current.clear()
    }
    
    const saveSign=async()=>{
        var draw = signRef.current.toDataURL().split(";base64,").pop()
        props.signature({type:"draw", base64:draw})
      }

  return (
    <div>
    <div className='' style={{maxWidth: "600px", height: "200px", marginLeft:"auto", marginRight:"auto", border:"2px solid DodgerBlue"}}>
        <SignaturePad
                canvasProps={{width:"600px", height:"200px",className: 'sigCanvas'}}
                ref={signRef}
                // backgroundColor="white"
                penColor="blue"
                
              />
        <p className='text-center text-dark'>Kindly draw your signature inside blue box</p>
        <hr/>
       
    </div>
    <div style={{marginTop:"198px"}}>
       
    </div>

    <div className='container mt-5' style={{maxWidth:"600px", marginLeft:"auto", marginRight:"auto"}}>
        <div className='row'>
            <div className='col-6 col-md-6 col-lg-6'>
                <button className='btn btn-info rounded-none w-100' onClick={saveSign}>Save</button>    
            </div>
            <div className='col-6 col-md-6 col-lg-6'>
                <p style={{cursor:"pointer"}} className='text-center text-danger mt-2' onClick={clearSign}>Clear</p>
            </div>

        </div>

    </div>
   
    </div>
  )
}

export default EsinDrawSignature
// esignature page
import { useRef, useState } from 'react';
import { Modal, Button } from "react-bootstrap";

import FormNeeded from '../webb/form-needed';

import { CreatePasscode ,CheckPasscode } from '../../services/srvc-esin-realm';
import '../../App.css'
import SignaturePad from 'react-signature-canvas'
import EsinDrawSignature from './esin-draw';
import EsinTypeSignature from './esin-type';
import EsinUploadSignature from './esin-upload';
export default function UserEsinMailModule (props) {


  const data = props.data;
  const features= props.feature
  //console.log(props)
  const enable=props.enable

  const [send, setSend] = useState(false);
  const [chck, setCheck] = useState(false);
  const [signstr,setSignstr]=useState('')
  const [show, setShow] = useState(false);
  const [code, setCode] = useState('');
  const [save,setSave]=useState(false)
  const [canvas,setCanvas]=useState(true)
  const [font,setFont]=useState(false)
  const [typedname,setTypedname]=useState('')

  const [draw, setDraw] = useState(true)
  const [type,setType] = useState(false)
  const [upload, setUpload] = useState(false)



  const [trxn,setTrxn]=useState()
 
  let signRef=useRef({})


  
  const CreateCode = async () => {
    // console.log (data.emid)
     setSend(true);
    const res = await CreatePasscode ({email:data.emid, name:data.name});
    // console.log(res)
    setTrxn(res.trxn)
    
    
  }

  const CheckCode = async () => {
    var obj={email:data.emid, otp:code.toString(),trxn:trxn}
    console.log(obj)
    // const res = await CheckPasscode (obj)
    // // console.log(res)
    
    //   if (res.status===true) {
    //     setCheck({text: 'Congratulations! Passcode Verified.', colr: 'text-success'})
    //     props.esin({"stat":true,"value":signstr, "name":typedname});
    //   } 
    //   else {
    //     setCheck({text: 'Passcode Incorrect. Please try again.', colr: 'text-danger'})
    //     props.esin({"stat":false, "value":""});
  
    //   }
    props.esin({"stat":true,"value":signstr, "name":typedname});
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



const saveSign=async(value)=>{
  console.log(value)
  setShow(false)
  if(value.type == "draw" || value.type == "upload")
  {
    props.esin({"stat":true,"value":value.base64, "name":""});
  }

  if(value.type=="type")
  {
    props.esin({"stat":true,"value":"", "name":value.name});
  }
  
  }
  

  const ApproveInvoice=async()=>{
    var datx={

    }
    //var res= await
  }


  const HandleModeChange= async(item)=>{

    if(item=="draw"){ setDraw(true);setType(false);setUpload(false)}
    if(item=="type"){ setDraw(false);setType(true);setUpload(false)}
    if(item=="upload"){ setDraw(false);setType(false);setUpload(true)}

  }


  return (
  <>
    <div className="rounded-wd back-color-wite">
      <div className="d-flex p-3">
        <div className="me-auto">
          <p className="text-bold mb-1">Name</p>
          <p className="m-0 p-0  text-small">Email</p>
        </div>
        <div className="text-end">
          <p className="text-bold mb-1">{data.name || '***'}</p>
          <p className="m-0 p-0 text-small text-sm">{data.emid || '***'}</p>
        </div>
      </div>

      <div className="border-bottom"></div>

      
      {/* code block 1 */}
      {
         send==false?
        <>
        <div className={`p-3 d-flex`}>

          <span className="me-auto btn btn-light border-none rounded-wd text-small p-2 px-3 "
            style={{width: '6rem'}}
            // onClick={handleShow}
          >Reject</span>

          <span className="btn btn-light border-none rounded-wd text-small p-2 px-3 d-none"
            style={{width: '6rem'}}
            onClick={handleShow}
          >Delegate</span>

          <span className='mx-1'></span>
          <span className="btn btn-info border-none back-color-main rounded-wd text-color-wite text-small p-2 px-3"
            style={{width: '6rem'}}
            onClick={handleShow}
          >E-Sign</span>

        </div>
        
        <div className={`p-3 text-center}`}>
        <div className="d-flex">

          <div className="text-end d-none">
            {
              features.only?
              <>
              <p style={{cursor:"pointer"}} className={`text-primary`} variant="primary" onClick={()=>{setSave(true);CreateCode()}} >
                Go For Approval
              </p>
              </>:
              <>
              <p style={{cursor:"pointer"}} className={`text-primary`} variant="primary" onClick={handleShow}>
                Go For Signature
              </p>
              </>
            }
            
          
      

            <Modal className="" dialogClassName={"primaryModal"} size="lg" show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title className="small fw-bold m-0 ">Add Your Signature</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='container'>
                  <div className='row'>
                    <div onClick={()=>HandleModeChange("draw")} style={{cursor:"pointer"}} className={`col-4 col-md-4 col-lg-4 text-center`}><i class={`bi bi-pencil-fill lg text-${draw?"info":"dark"}`}></i></div>
                    <div onClick={()=>HandleModeChange("type")} style={{cursor:"pointer"}} className={`col-4 col-md-4 col-lg-4 text-center`}><i class={`bi bi-keyboard-fill lg text-${type?"info":"dark"}`}></i></div>
                    <div onClick={()=>HandleModeChange("upload")} style={{cursor:"pointer"}} className={`col-4 col-md-4 col-lg-4 text-center`}><i class={`bi bi-file-arrow-up-fill lg text-${upload?"info":"dark"}`}></i></div>

                  </div>
                  <div className='row'>
                    <div onClick={()=>HandleModeChange("draw")} style={{cursor:"pointer"}} className={`col-4 col-md-4 col-lg-4 text-center text-${draw?"primary":"dark"} `}>Draw</div>
                    <div onClick={()=>HandleModeChange("type")} style={{cursor:"pointer"}} className={`col-4 col-md-4 col-lg-4 text-center text-${type?"primary":"dark"} `}>Type</div>
                    <div onClick={()=>HandleModeChange("upload")} style={{cursor:"pointer"}} className={`col-4 col-md-4 col-lg-4 text-center text-${upload?"primary":"dark"} `}>Upload</div>

                  </div>

                  <div className='row'>
                    <div className='col-4 col-md-4 col-lg-4 text-center fw-bold'><hr style={{height:draw?"2px":"1px"}} className={`bg-${draw?"info":""}`}/></div>
                    <div className='col-4 col-md-4 col-lg-4 text-center'><hr style={{height:type?"2px":"1px"}} className={`bg-${type?"info":""}`}/></div>
                    <div className='col-4 col-md-4 col-lg-4 text-center'><hr style={{height:upload?"2px":"1px"}} className={`bg-${upload?"info":""}`}/></div>

                  </div>

                </div>

                <div className={`${draw?"":"d-none"}`}>
                    <EsinDrawSignature signature = {saveSign} />
                </div>
                <div className={`${type?"":"d-none"}`}>
                  <EsinTypeSignature  name={data.name} signature={saveSign}/>
                </div>
                <div className={`${upload?"":"d-none"}`}>
                  <EsinUploadSignature signature={saveSign}/>
                </div>
              
              </Modal.Body>
              <Modal.Footer>

                
              </Modal.Footer>
            </Modal>
         
           
            
          </div>
        </div>
      </div>
        </>:
        <>
        </>
      }
   

      

      {/* code input */}
      {
        send==true?
        <>
        <div className={`p-3 text-center }`}>
        <div className="form-group">
          <label className="form-label small">Enter OTP / Passcode <FormNeeded/></label>
          <input type="text" 
            disabled={enable}
            className="form-control height-md bg-lite"
            style={{fontSize:'0.9rem', height:'2.7rem', textAlign:'center'}}
            value={data.code}
            onChange={({ target }) => {setCode(target.value); setCheck({})}}
            placeholder="123456">
          </input>
        </div>
      </div>
        </>:
        <>
        </>
      }
      

      {/* code status */}
      <div className={`px-3 mb-3 text-center ${data.stat==0 && send ? '' : 'd-none'}`}>
        <p className={chck.colr}>{chck.text}</p>
      </div>


      {/* code action */}
      <div className={`px-3 mb-3 text-center ${data.stat==0 && send ? '' : 'd-none'}`}>
        <div className="d-flex">
          <div className="me-auto">
            <button
              className="btn btn-outline-secondary border-none btn-sm px-0 rounded-pill"
              onClick={()=> CreateCode()}
            ><small>Resend OTP</small></button>
          </div>
          <div className="text-end">

            <button
              className="btn btn-primary btn-sm px-3 rounded-pill"
              // disabled={code.length!==6 || isNaN(code)}
              onClick={()=> CheckCode()}
              ><small>Submit</small>
            </button>
          </div>
        </div>
      </div>

    </div>

  </>
  )
}
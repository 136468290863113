// esin status
import icon from '../../media/icon.png';
import WebbIcon from '../webb/webb-icon';
import WebbSiteName from '../webb/webb-site-name';


export default function UserEsinDone() {

  return (
  <>
  
    <div className="" style={{height:'24vh'}}></div>
    <div className="text-center text-primary lead py-2">
    <WebbIcon/>
      <p className="">Document Completed or Link has Expired</p>
    </div>

    <div className="fixed-bottom text-center" style={{bottom: '1.5rem'}}>
      <WebbSiteName/>
    </div>


  </>
  )
}
import React, { useState } from 'react'
import { ResizeSignatureImage } from '../../services/srvc-esin-realm';

const EsinUploadSignature = (props) => {

    const [file,setFile] = useState()
    const [button, setButton] = useState(true)
    const [process,setProcess] = useState(false)

    const [imgData, setImgData] = useState(null);
    const [picture, setPicture] = useState(null);

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });

    const UploadFiles = async(e)=>{
       
        

        if (e.target.files[0]) {
            // console.log("picture: ", e.target.files);
            setPicture(e.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
              setImgData(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
          }
        setFile(e.target.files[0])

       
        
    }

    const SubmitFile = async()=>{

        setProcess(true)
        setButton(false)
        var str = await toBase64(file)
        var base64 = str.split(";base64,").pop()
        var result = await ResizeSignatureImage({"imagebase64":base64, "size":{"width":"350","height":"150"}})
        // setFile(result.resizedImage)
        props.signature({type:"upload", base64:result.resizedImage})

    }


  return (
    <div>
    <div className='' style={{maxWidth: "600px", height: "100px", marginLeft:"auto", marginRight:"auto", borderStyle:"dotted"}}>
       <div className='text-center' style={{maxWidth:"600px",marginLeft:"auto", marginRight:"auto",opacity:"0.3"}}>
       <input type="file" accept="image/png" onChange={(e)=>UploadFiles(e)} className='btn btn-success rounded-none text-center text-light' style={{marginTop:"20px"}} />
       <p className='mt-2'>You can upload your png Signature Image</p>
       </div>

       <div className={`previewProfilePic text-center mt-5 ${file?"":"d-none"}`}>
            <img style={{width:"260px", height:"160px"}} className="playerProfilePic_home_tile" src={imgData} />
        </div>
      
    </div>

    <div className='' style={{marginTop:"298px"}}>
       
    </div>

    <div className='container mt-5' style={{maxWidth:"600px", marginLeft:"auto", marginRight:"auto"}}>
        <div className='row'>
            <div className='col-6 col-md-6 col-lg-6'>
                <button disabled={`${file!=null?"":true}`} onClick={()=>SubmitFile()} className={`btn btn-info rounded-none w-100 ${button?"":"d-none"}`}>Save</button>    
                <p className={`${process?"":"d-none"}`}>Uploading......</p>
            </div>
            <div className='col-6 col-md-6 col-lg-6'>
                <p style={{cursor:"pointer"}} className='text-center text-danger mt-2' >Clear</p>
            </div>

        </div>

    </div>
   
    </div>
  )
}

export default EsinUploadSignature
// main
import LayoutContentMain from "../content/webc/layout-content-mn";

import WebbDividerMedium from "../content/webb/webb-divider-md";

import InvoiceViewModule from "../content/appr/esin-docx-view";

export default function InvoiceApprovalMail () {


  return(
    <>
      <LayoutContentMain 
        head='Sign Document'
        link={``}
        form= 'medium'
        data= {
          <>
            <InvoiceViewModule />

            <WebbDividerMedium />
            <WebbDividerMedium />
          </>
        } 
      />
    </>
  )
}
// init
import { AuthWebbUser } from './srvc-auth-realm';
import axios from 'axios'
import list from '../data/data-routes.json'

// document info


//

export const GetBulkDocumentInfoAdhr = async (item) => {
  const basx = 'https://ap-south-1.aws.data.mongodb-api.com/app/contrato-esin-ovqdo/endpoint/bulk/document/read';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = {data:item}
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }
}


export const GetBulkDocumentInfoMail = async (item) => {
  const basx = 'https://ap-south-1.aws.data.mongodb-api.com/app/contrato-esin-rdujc/endpoint/bulk/document/read';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = {data:item}
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }
}

export const GetDocumentInfoMail = async (item) => {
  const basx = 'https://ap-south-1.aws.data.mongodb-api.com/app/contrato-esin-pczds/endpoint/document/read';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = {data:item}
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }

}

export const GetDocumentInfoAdhr = async (item) => {
  const basx = 'https://ap-south-1.aws.data.mongodb-api.com/app/contrato-esin-inxpz/endpoint/document/read';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      console.log (error);
      return {data: false}
    }

}





export const getKycTransactionData = async (item) => {

  const basx = 'https://ap-south-1.aws.data.mongodb-api.com/app/contrato-iamx-cahll/endpoint/iamx/get/transaction';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      console.log (error);
      return {data: false}
    }
  
}


export const SetDocumentInfo = async (item) => {
  
//  var result= await axios.post("https://68cjq7i0z8.execute-api.ap-south-1.amazonaws.com/capturesign",item)
 var result = await axios.post("https://ap-south-1.aws.data.mongodb-api.com/app/contrato-docx-uqaiv/endpoint/document/capture/signature",item )
 console.log(result.data)
 return result.data
}

export const CreatePasscode = async (item) => {

  var payload={
    "data":{
        "user":item.email,
        "channel":"emid",
        "memo":"Email Verification"
    },
    "user":item.email
}
var result= await axios.post("https://ap-south-1.aws.data.mongodb-api.com/app/contrato-auth-mpyie/endpoint/code/create",payload)
 var {data}= result.data
//  console.log(data)
 return data
}

export const CheckPasscode = async (item) => {

  var payload={
    "data":{
        "user":item.email,
        "trxn":item.trxn,
        "code":item.otp
    },
    "user":item.email,
}
var result
await axios.post("https://ap-south-1.aws.data.mongodb-api.com/app/contrato-auth-mpyie/endpoint/code/check",payload)
.then((res=>{
   result=res.data.data
}))
.catch((err)=>{
  result=err.response.data.data
  
})

//console.log(result)
return result

}

export const DeligateUser = async (item) => {

  const user = await AuthWebbUser();
  const result = await user.functions.DeligateToUser(item)
  return (result);
}

export const uploadDocs = async (item) => {

  

}


export const saveKYCDocs = async (item) => {

  var head={
    "headers":{
      "Authorization":"a12af9110e2c4985a7f650c8e6dea20f1"
    }
  }

  var result
  await axios.post("https://ap-south-1.aws.data.mongodb-api.com/app/contrato-files-dyohy/endpoint/media/save",item,head)
  .then((res=>{
        result=res.data.data
    }))
    .catch((err)=>{
      result=err.response.data.data
      
    })
 
 //console.log(result)
 return result
}

export const VerifyUserPAN = async (item) => {

var data = {data:{number:item.number,name:item.name},user:"9cef0ff4381b408da86b59a3c0a00fdd8"}

var result
await axios.post("https://ap-south-1.aws.data.mongodb-api.com/app/contrato-iamx-cahll/endpoint/indx/pancard/check",data)
.then((res=>{
   result=res.data.data
}))
.catch((err)=>{
  result=err.response.data.data
  
})

//console.log(result)
return result
  
  
}

export const VerifyBusinessPAN = async (item) => {

  var data = item

  var result
  await axios.post("https://ap-south-1.aws.data.mongodb-api.com/app/contrato-iamx-cahll/endpoint/bznx/pancard/check",data)
  .then((res=>{
     result=res.data.data
  }))
  .catch((err)=>{
    result=err.response.data.data
    
  })
  
  //console.log(result)
  return result


}

export const saveUserAction = async (item) => {

  const basx ='https://ap-south-1.aws.data.mongodb-api.com/app/contrato-track-snwie/endpoint/track/save';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = item

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    //console.log (error);
    return {data: false}
  }

}

export const ResizeSignatureImage = async (item) => {

  //const basx ='https://vf0ut6ogv3.execute-api.ap-south-1.amazonaws.com/resizeimage';
  const basx = "https://ap-south-1.aws.data.mongodb-api.com/app/contrato-docx-uqaiv/endpoint/resize/signature/image"
  
  const datx = item

  var result;
  try {
    result = await axios.post(basx, datx);
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    //console.log (error);
    return {data: false}
  }

}

export const ApproveInvoice = async (item) => {

  const user = await AuthWebbUser();
  const result = await user.functions.saveDirectHookFromApp(JSON.stringify({data:item}))
  return (result);
}



import React from 'react'

const BasicInfoModule = (props) => {
    
var {data} = props
    

  return (
    <div>
        <div className="d-flex p-3">
        <div className="me-auto">
          <p className="">Name</p>
          <p className="m-0 p-0">Email</p>
        </div>
        <div className="text-end">
          <p className="">{data.name || '***'}</p>
          <p className="m-0 p-0">{data.emid || '***'}</p>
        </div>
      </div>
    </div>
  )
}

export default BasicInfoModule
// webbsite loader
import icon from '../../media/icon.png';
import WebbSiteName from './webb-site-name';

export default function WebbLoaderIcon(props) {

  return (
  <>
    <div className="" style={{height:'24vh'}}></div>
    <div className="text-center py-1">
      <i 
          className='bx bxs-chevron-up-circle text-color-main' 
          style={{fontSize:'2.4rem'}}
        ></i>
      <p className="">{props.text ? props.text : 'Please Wait...'}</p>
    </div>
  </>
  )
}
//loader
export default function WebbSiteName() {
 
  return (
  <>
    {/* spinner */}
    <div className="text-center">
      <p className="text-tone small m-0">
        {process.env.REACT_APP_WEBB_SITE_NAME}
        {' - '}
        {process.env.REACT_APP_WEBB_SITE_LINE}
      </p>
    </div>
  </>
  )
}
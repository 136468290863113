//  main
import LayoutContentMain from "../content/webc/layout-content-mn";

import WebbDividerMedium from "../content/webb/webb-divider-md";
import WebbDividerSmall from "../content/webb/webb-divider-sm";
import WebbIcon from "../content/webb/webb-icon";

export default function Land () {

  return(
    <LayoutContentMain 
      head='Main'
      link={``}
      form= 'mini'
      data= {
          <>
          <div className="" style={{height:'9vh'}}></div>
            <div className="text-center border rounded-wd p-3 back-color-wite shadow-sm">
              <WebbDividerMedium />
              <i className="bx bxs-chevron-up-circle caption-wd text-color-main"></i>

              <WebbDividerSmall />
              <h1 className="fw-bold text-color-main text-uppercase" 
                style={{fontSize:'1.9rem'}}>
                {process.env.REACT_APP_WEBB_SITE_NAME}
              </h1>

              <WebbDividerMedium />
              <p className="m-0"> {process.env.REACT_APP_WEBB_SITE_LINE}</p>

              <WebbDividerMedium />
              <WebbDividerMedium />
              <a className="btn btn-light rounded-wd text-small p-2 px-3 " href='https://contrato.ws'>Main Website</a>

              <WebbDividerMedium />
              <WebbDividerMedium />
            </div>
            
          </>
      } 
    />
  )
}